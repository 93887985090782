import React, { useEffect, useRef } from 'react';

function Percentile({ percentage }) {
  const normalDistRef = useRef(null);
  const percentileValue = Math.floor(percentage * 100);

  useEffect(() => {
    const canvas = normalDistRef.current;
    const ctx = canvas.getContext('2d');
    const width = 768;
    const height = 520;

    // Canvas 초기화
    ctx.clearRect(0, 0, width, height);

    // 점의 X 좌표 계산 (6~94% 범위로 제한)
    const limitedPercentile = Math.min(94, Math.max(6, percentileValue));
    const pointX = width - (width * limitedPercentile / 100);

    // 정규분포 곡선 그리기
    ctx.beginPath();
    ctx.moveTo(40, 480);
    
    // 곡선 그리기
    ctx.bezierCurveTo(
      192, 480,
      192, 160,
      384, 160
    );
    
    ctx.bezierCurveTo(
      576, 160,
      576, 480,
      728, 480
    );

    // 영역 채우기 경로 설정
    ctx.lineTo(728, 480);
    ctx.lineTo(40, 480);
    
    // 배경 채우기
    ctx.fillStyle = 'rgba(74, 188, 133, 0.1)';
    ctx.fill();
    
    // 선 그리기
    ctx.beginPath();
    ctx.moveTo(40, 480);
    ctx.bezierCurveTo(
      192, 480,
      192, 160,
      384, 160
    );
    ctx.bezierCurveTo(
      576, 160,
      576, 480,
      728, 480
    );
    ctx.strokeStyle = '#4ABC85';
    ctx.lineWidth = 15;
    ctx.stroke();

    // 베지어 곡선 위의 점 계산
    const getBezierPoint = (t) => {
      const isLeft = pointX <= width/2;
      
      let p0, p1, p2, p3;
      
      if (isLeft) {
        p0 = { x: 48, y: 480 };
        p1 = { x: 192, y: 480 };
        p2 = { x: 192, y: 160 };
        p3 = { x: 384, y: 160 };
      } else {
        p0 = { x: 384, y: 160 };
        p1 = { x: 576, y: 160 };
        p2 = { x: 576, y: 480 };
        p3 = { x: 728, y: 480 };
      }
      
      const x = Math.pow(1-t, 3) * p0.x + 
                3 * Math.pow(1-t, 2) * t * p1.x + 
                3 * (1-t) * Math.pow(t, 2) * p2.x + 
                Math.pow(t, 3) * p3.x;
                
      const y = Math.pow(1-t, 3) * p0.y + 
                3 * Math.pow(1-t, 2) * t * p1.y + 
                3 * (1-t) * Math.pow(t, 2) * p2.y + 
                Math.pow(t, 3) * p3.y;
                
      return { x, y };
    };

    // 이진 탐색으로 가장 가까운 t 값 찾기
    let left = 0;
    let right = 1;
    let bestT = 0.5;
    let bestDist = Infinity;

    for (let i = 0; i < 10; i++) {
      const t1 = left + (right - left) / 3;
      const t2 = right - (right - left) / 3;
      
      const p1 = getBezierPoint(t1);
      const p2 = getBezierPoint(t2);
      
      const dist1 = Math.abs(p1.x - pointX);
      const dist2 = Math.abs(p2.x - pointX);
      
      if (dist1 < bestDist) {
        bestT = t1;
        bestDist = dist1;
      }
      if (dist2 < bestDist) {
        bestT = t2;
        bestDist = dist2;
      }
      
      if (dist1 < dist2) {
        right = t2;
      } else {
        left = t1;
      }
    }

    const point = getBezierPoint(bestT);

    // 위치 표시 점 그리기
    // 흰색 테두리 원
    ctx.beginPath();
    ctx.arc(pointX, point.y, 22, 0, Math.PI * 2);  // 더 큰 크기의 원
    ctx.fillStyle = '#FFFFFF';
    ctx.fill();
    
    // 초록색 내부 원
    ctx.beginPath();
    ctx.arc(pointX, point.y, 16, 0, Math.PI * 2);
    ctx.fillStyle = '#4ABC85';
    ctx.fill();

    // 상위 % 텍스트 박스 그리기
    const boxWidth = 150;
    const boxHeight = 70;
    const safeMargin = 40;  // 안전 여백
    const leftBoundary = safeMargin;  // 왼쪽 경계
    const rightBoundary = width - safeMargin;  // 오른쪽 경계
    
    // 점의 x 좌표에 따라 박스 위치 결정
    let boxX;
    if (pointX <= leftBoundary + (boxWidth / 2)) {
      // 왼쪽 끝 부분
      boxX = leftBoundary;
    } else if (pointX >= rightBoundary - (boxWidth / 2)) {
      // 오른쪽 끝 부분
      boxX = rightBoundary - boxWidth;
    } else {
      // 중간 영역
      boxX = pointX - (boxWidth / 2);
    }

    const boxY = point.y - 100;

    // 박스 그리기
    ctx.fillStyle = '#4ABC85';
    ctx.beginPath();
    ctx.roundRect(boxX, boxY, boxWidth, boxHeight, 20);
    ctx.fill();

    // 박스 테두리
    ctx.strokeStyle = '#FFFFFF';
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.roundRect(boxX, boxY, boxWidth, boxHeight, 20);
    ctx.stroke();

    // 텍스트는 항상 박스의 중앙에 위치하도록 수정
    ctx.fillStyle = '#FFFFFF';
    ctx.font = 'bold 25px sans-serif';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(`상위 ${percentileValue}%`, boxX + (boxWidth/2), boxY + (boxHeight/2 + 2));

  }, [percentileValue]);

  return (
    <div className="w-full rounded-2xl p-4 shadow-[0_0_6px_rgba(0,0,0,0.1)]">
      <p className="text-center text-[12px] font-semibold text-[#231F20] mb-[14px]">
        내 실력에 대한 백분위를 확인해보세요
      </p>
      <canvas
        ref={normalDistRef}
        width={768}
        height={520}
        className="w-full"
      />
    </div>
  );
}

export default Percentile;
