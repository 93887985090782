import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import logoAnalysis from '../assets/logo_analysis.png';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import axios from 'axios';
import FloatingNavButtons from '../components/FloatingNavButtons';

function MyResultMapPage() {
  const navigate = useNavigate();
  const { code } = useParams();
  const [data, setData] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState('d4');
  const [examList, setExamList] = useState([]);
  const [currentExamIndex, setCurrentExamIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showFloatingCard, setShowFloatingCard] = useState(false);
  const cardRef = useRef(null);
  const [showOnlyProblems, setShowOnlyProblems] = useState(false);
  const [problemHighlights, setProblemHighlights] = useState(new Set());


  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const name = localStorage.getItem('student_name');
    const email = localStorage.getItem('student_email');

    if (!token || !name || !email) {
      navigate('/', { replace: true });
      return;
    }

    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/student/resultmap`,
          {
            params: {
              exam_id: code
            },
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        
        if (response.data.status === 'success' && isMounted) {
          const responseData = response.data.data;
          setData({
            tree_name: responseData.tree_name,
            edu_ver: responseData.edu_ver,
            exam_type: responseData.exam_type,
            exam_count: responseData.exam_count,
            difficulty: responseData.difficulty,
            length: responseData.length,
            updated_at: responseData.updated_at,
            problems: responseData.problems,
            result_map: responseData.result_map
          });

          // 이전/다음 시험 ID가 있는 경우 examList 업데이트
          if (responseData.prev_exam !== null || responseData.next_exam !== null) {
            const exams = [];
            if (responseData.prev_exam) exams.push({ exam_id: responseData.prev_exam });
            exams.push({ exam_id: parseInt(code) });
            if (responseData.next_exam) exams.push({ exam_id: responseData.next_exam });
            setExamList(exams);
            setCurrentExamIndex(responseData.prev_exam ? 1 : 0);
          }
        }
      } catch (error) {
        console.error('데이터를 불러오는데 실패했습니다:', error);
        if (error.response?.status === 401) {
          localStorage.clear();
          navigate('/', { replace: true });
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [code, navigate]);

  useEffect(() => {
    const handleScroll = () => {
      if (cardRef.current) {
        const cardPosition = cardRef.current.getBoundingClientRect().top;
        setShowFloatingCard(cardPosition < -150);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (data?.problems) {
      const highlights = new Set(
        Object.entries(data.problems)
          .map(([_, problem]) => problem[1])
      );
      setProblemHighlights(highlights);
    }
  }, [data]);

  const colors = {
    100: 'bg-[#F9344B]',
    // 101: 'bg-[#F9344B] opacity-10',
    101: 'bg-[#FDCECE] opacity-70' ,
    200: 'bg-[#4ABC85]',
    // 201: 'bg-[#4ABC85] opacity-10',
    201: 'bg-[#B7E4CE] opacity-70',
    500: 'bg-[#FAEECE]',
    0: 'after:content-["×"]'
  };

  const getAddressPrefix = (level) => {
    // 제거: 더 이상 prefix가 필요하지 않음
    return '';
  };

  const renderStatusBox = (status, address, level) => {
    // 중유형(d4)일 때만 문제 표시
    if (selectedLevel === 'd4') {
      // 해당 주소와 레벨의 모든 문제 찾기
      const problems = Object.entries(data?.problems || {})
        .filter(([id, problem]) => {
          const [number, path, problemLevel] = problem;
          return path === address && problemLevel === (level + 1);
        })
        .map(([id, problem]) => problem[0]); // 문제 번호만 추출

      const baseClasses = `w-6 h-6 rounded-md ${colors[status] || 'bg-gray-200'} relative flex items-center justify-center`;
      
      return (
        <div 
          className={`${baseClasses} ${
            problems.length > 0 ? 'border-2 border-black' : ''
          }`}
        >
          {problems.length > 0 && (
            <div className="text-[8px] font-bold text-black">
              {problems.map((num, idx) => (
                <span key={idx}>
                  {num}{idx < problems.length - 1 ? ',' : ''}
                </span>
              ))}
            </div>
          )}
        </div>
      );
    } else {
      // 다른 레벨일 때는 기본 상태박스만 표시
      return (
        <div 
          className={`w-6 h-6 rounded-md ${colors[status] || 'bg-gray-200'}`}
        />
      );
    }
  };

  // 각 레벨별 데이터 추출
  const getLevelData = () => {
    if (!data?.result_map) return [];

    const levelMap = {
      'd1': data.result_map.d1_y_axis,
      'd2': data.result_map.d2_y_axis,
      'd3': data.result_map.d3_y_axis,
      'd4': data.result_map.d4_y_axis
    };

    const currentLevelData = levelMap[selectedLevel];
    if (!currentLevelData) return [];

    const entries = Object.entries(currentLevelData);
    
    // 중유형(d4)이고 토글이 켜져있을 때만 필터링
    if (selectedLevel === 'd4' && showOnlyProblems) {
      return entries
        .filter(([key]) => {
          return Object.entries(data.problems || {}).some(([_, problem]) => 
            problem[1].startsWith(key)
          );
        })
        .map(([key, value]) => ({
          key,
          name: value[0],
          status: value[1]
        }));
    }

    return entries.map(([key, value]) => ({
      key,
      name: value[0],
      status: value[1]
    }));
  };

  // 레벨 순환 처리
  const getNextLevel = (currentLevel) => {
    const levels = {
      'd1': 'd2',
      'd2': 'd3',
      'd3': 'd4',
      'd4': 'd1'
    };
    const nextLevel = levels[currentLevel];
    if (nextLevel !== 'd4') {
      setShowOnlyProblems(false);
    }
    return nextLevel;
  };

  // 이전 레벨 가져오기 함수 수정
  const getPrevLevel = (currentLevel) => {
    const levels = {
      'd1': 'd4',
      'd2': 'd1',
      'd3': 'd2',
      'd4': 'd3'
    };
    const prevLevel = levels[currentLevel];
    if (prevLevel !== 'd4') {
      setShowOnlyProblems(false);
    }
    return prevLevel;
  };

  // 레벨별 표시 텍스트
  const levelTitles = {
    'd4': '중유형',
    'd3': '대유형',
    'd2': '소단원',
    'd1': '대단원'
  };

  // 레벨별 폰트 크기 설정 추가
  const levelFontSizes = {
    'd1': 'text-[14px] font-bold',
    'd2': 'text-[14px]',
    'd3': 'text-[12px] font-bold',
    'd4': 'text-[12px]'
  };

  // 뒤로가기 버튼 클릭 핸들러
  const handleBack = () => {
    navigate(`/myresult/${code}`);
  };

  const handleNextExam = () => {
    if (examList.length > 0 && currentExamIndex < examList.length - 1) {
      const nextExamId = examList[currentExamIndex + 1].exam_id;
      navigate(`/myresult/${nextExamId}/map`);
    }
  };

  const handlePrevExam = () => {
    if (examList.length > 0 && currentExamIndex > 0) {
      const prevExamId = examList[currentExamIndex - 1].exam_id;
      navigate(`/myresult/${prevExamId}/map`);
    }
  };

  // 토글 버튼 핸들러 추가
  const handleToggleChange = (checked) => {
    if (checked && selectedLevel !== 'd4') {
      setSelectedLevel('d4');
    }
    setShowOnlyProblems(checked);
  };

  // 토글 버튼 렌더링 함수 수정
  const renderToggleButton = () => {
    return (
      <div className="flex items-center justify-center gap-4">
        <span className="text-[14px] text-[#231F20]">출제 문제만 보기</span>
        <button
          onClick={() => handleToggleChange(!showOnlyProblems)}
          className={`
            relative inline-flex h-6 w-11 items-center rounded-full
            ${showOnlyProblems ? 'bg-[#4ABC85]' : 'bg-gray-200'}
            transition-colors duration-200
          `}
        >
          <span
            className={`
              inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200
              ${showOnlyProblems ? 'translate-x-6' : 'translate-x-1'}
            `}
          />
        </button>
      </div>
    );
  };

  // 로딩 상태 체크
  if (isLoading) return (
    <div className="flex flex-col min-h-screen items-center justify-center">
      <div className="w-12 h-12 border-4 border-[#4ABC85] border-t-transparent rounded-full animate-spin"></div>
      <p className="mt-4 text-gray-600 font-medium animate-pulse">분석 중입니다...</p>
    </div>
  );

  const formatExamDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(2);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <main className="flex-grow flex flex-col items-center pt-[12px] relative">
        <div className="w-[384px] max-w-[100vw] flex items-center mb-4">
          <button 
            onClick={handleBack}
            className="flex items-center text-gray-600 hover:text-gray-800"
          >
            <ChevronLeft size={24} />
            <span className="text-sm font-medium">결과로</span>
          </button>
        </div>
        <div className="text-xl font-bold mb-10">나의 폼</div>
        {data && (
          <div ref={cardRef} className="w-[384px] text-sm font-medium text-center mb-[20px]">
            <div className="w-full rounded-2xl p-6 shadow-[0_0_6px_rgba(0,0,0,0.1)]">
              <div className="flex flex-col">
                <div className="grid grid-cols-9 items-center text-[14px] text-center">
                  <span className="col-span-3 font-bold text-[#231F20]">
                    {data.tree_name}
                  </span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 font-bold text-[#231F20]">
                    {data.exam_type?.charAt(0).toUpperCase() + 
                     data.exam_type?.slice(1).toLowerCase() || '유형'}
                  </span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#231F20]">
                    {formatExamDate(data.updated_at)}
                  </span>
                </div>
                <div className="w-full h-[1px] bg-[#E5E5E5] my-[10px]"></div>
                <div className="grid grid-cols-9 items-center text-[14px] text-center">
                  <span className="col-span-3 text-[#7B7979]">회차   <span className="text-[14px] text-[#231F20]">{data.exam_count} </span><span className="text-[12px] text-[#231F20]">(TID · {code})</span></span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#7B7979]">난이도   <span className="text-[14px] text-[#231F20]">{(data.difficulty / 100).toFixed(2)}</span></span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#7B7979]">문제수   <span className="text-[14px] text-[#231F20]">{data.length}</span></span>
                </div>
              </div>
            </div>
          </div>
        )}
        {showFloatingCard && data && (
          <div className="fixed top-0 left-1/2 transform -translate-x-1/2 w-[384px] text-sm font-medium text-center z-50 pt-4 pb-2">
            <div className="w-full rounded-2xl p-6 bg-white shadow-[0_0_6px_rgba(0,0,0,0.1)]">
              <div className="flex flex-col">
                <div className="grid grid-cols-9 items-center text-[14px] text-center">
                  <span className="col-span-3 font-bold text-[#231F20]">
                    {data.tree_name}
                  </span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 font-bold text-[#231F20]">
                    {data.exam_type?.charAt(0).toUpperCase() + 
                     data.exam_type?.slice(1).toLowerCase() || '유형'}
                  </span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#231F20]">
                    {formatExamDate(data.updated_at)}
                  </span>
                </div>
                <div className="w-full h-[1px] bg-[#E5E5E5] my-[10px]"></div>
                <div className="grid grid-cols-9 items-center text-[14px] text-center">
                  <span className="col-span-3 text-[#7B7979]">회차   <span className="text-[14px] text-[#231F20]">{data.exam_count} </span><span className="text-[12px] text-[#231F20]">(TID · {code})</span></span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#7B7979]">난이도   <span className="text-[14px] text-[#231F20]">{(data.difficulty / 100).toFixed(2)}</span></span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#7B7979]">문제   <span className="text-[14px] text-[#231F20]">{data.length}</span></span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="w-[384px] rounded-2xl p-6 shadow-[0_0_6px_rgba(0,0,0,0.1)] mb-[20px]">
          <div className="flex gap-4 text-[10px] justify-center mb-[10px]">
            <div className="flex items-center gap-1">
              <div className="w-3 h-3 rounded bg-[#B7E4CE] opacity-70"></div>
              <span>Likely Correct</span>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-3 h-3 rounded bg-[#4ABC85]"></div>
              <span>Correct</span>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-3 h-3 rounded bg-[#FDCECE] opacity-70"></div>
              <span>Likely Incorrect</span>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-3 h-3 rounded bg-[#F9344B]"></div>
              <span>Incorrect</span>
            </div>
          </div>
          <div className="w-full h-[1px] bg-[#E5E5E5] mb-[24px]"></div>
          {renderToggleButton()}
        </div>
        <div 
          className="w-[384px] overflow-x-auto mb-[40px]" 
          style={{ 
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            WebkitOverflowScrolling: 'touch'
          }}
        >
          <style jsx>{`
            div::-webkit-scrollbar {
              display: none;
            }
          `}</style>
          <table className="w-full text-xs">
            <thead>
              <tr>
                <th className="px-2 py-1 text-center">
                  <div className="flex items-center justify-center gap-3">
                    <button 
                      onClick={() => setSelectedLevel(getPrevLevel(selectedLevel))}
                      className="w-5 h-5 flex items-center justify-center bg-[#4ABC85] rounded-full text-white hover:bg-[#3da575] disabled:opacity-30"
                      disabled={selectedLevel === 'd1'}
                    >
                      <ChevronLeft size={14} />
                    </button>
                    <span className="text-[10px] text-gray-900">
                      {levelTitles[selectedLevel]}
                    </span>
                    <button 
                      onClick={() => setSelectedLevel(getNextLevel(selectedLevel))}
                      className="w-5 h-5 flex items-center justify-center bg-[#4ABC85] rounded-full text-white hover:bg-[#3da575] disabled:opacity-30"
                      disabled={selectedLevel === 'd4'}
                    >
                      <ChevronRight size={14} />
                    </button>
                  </div>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 1</span>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 2</span>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 3</span>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 4</span>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 5</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {getLevelData().map((item) => (
                <tr key={item.key} className="h-10">
                  <td className="px-2 text-left">
                    <span className={levelFontSizes[selectedLevel]}>{item.name}</span>
                  </td>
                  {item.status.map((status, levelIndex) => (
                    <td key={levelIndex} className="px-1 text-center">
                      {renderStatusBox(status, item.key, levelIndex)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
      
      {examList.length > 0 && (
        <FloatingNavButtons
          onPrev={handlePrevExam}
          onNext={handleNextExam}
          disablePrev={currentExamIndex === 0}
          disableNext={currentExamIndex === examList.length - 1}
        />
      )}
    </div>
  );
}

export default MyResultMapPage;