import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import logoAnalysis from '../assets/logo_analysis.png';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';

function ResultMapPage() {
  const navigate = useNavigate();
  const { code } = useParams();
  const [data, setData] = useState(null);
  const [examInfo, setExamInfo] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState('d4');
  const [isLoading, setIsLoading] = useState(true);
  const [showFloatingCard, setShowFloatingCard] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/result/${code}`;
          
        const response = await fetch(endpoint);
        const json = await response.json();
        
        if (json.status === 'success' && isMounted) {
          setData(json.data.field_info);
          setExamInfo(json.data.exam_info);
        }
      } catch (error) {
        console.error('데이터를 불러오는데 실패했습니다:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [code]);

  useEffect(() => {
    const handleScroll = () => {
      if (cardRef.current) {
        const cardPosition = cardRef.current.getBoundingClientRect().top;
        setShowFloatingCard(cardPosition < -150);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const formatExamDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(2);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  };

  if (isLoading) return (
    <div className="flex flex-col min-h-screen items-center justify-center">
      <div className="w-12 h-12 border-4 border-[#4ABC85] border-t-transparent rounded-full animate-spin"></div>
      <p className="mt-4 text-gray-600 font-medium animate-pulse">분석 중입니다...</p>
    </div>
  );

  const colors = {
    100: 'bg-[#F9344B]',
    // 101: 'bg-[#F9344B] opacity-10',
    101: 'bg-[#FDCECE] opacity-70' ,
    200: 'bg-[#4ABC85]',
    // 201: 'bg-[#4ABC85] opacity-10',
    201: 'bg-[#B7E4CE] opacity-70',
    500: 'bg-[#FAEECE]',
    0: 'after:content-["×"]'
  };

  const renderStatusBox = (status) => {
    return (
      <div className={`w-6 h-6 rounded-md rounded ${colors[status] || 'bg-gray-200'}`} />
    );
  };

  // 각 레벨별 데이터 추출
  const getLevelData = () => {
    if (!data) return [];

    const levelMap = {
      d1: {
        title: '대단원',
        data: data.d1_y_axis || []
      },
      d2: {
        title: '소단원',
        data: data.d2_y_axis || []
      },
      d3: {
        title: '대유형',
        data: data.d3_y_axis || []
      },
      d4: {
        title: '중유형',
        data: data.d4_y_axis || []
      }
    };

    return levelMap[selectedLevel]?.data?.map(item => ({
      name: item[1]
        .split('_(D5)')[0]
        .replace(/\([^)]*\)/g, '')
        .trim(),
      status: item[3]
    })) || [];
  };

  // 레벨 순환 처리
  const getNextLevel = (currentLevel) => {
    const levels = {
      'd1': 'd2',
      'd2': 'd3',
      'd3': 'd4',
      'd4': 'd1'
    };
    return levels[currentLevel];
  };

  // 이전 레벨 가져오기 함수 수정
  const getPrevLevel = (currentLevel) => {
    const levels = {
      'd1': 'd4',
      'd2': 'd1',
      'd3': 'd2',
      'd4': 'd3'
    };
    return levels[currentLevel];
  };

  // 레벨별 표시 텍스트
  const levelTitles = {
    'd4': '중유형',
    'd3': '대유형',
    'd2': '소단원',
    'd1': '대단원'
  };

  // 레벨별 폰트 크기 설정 추가
  const levelFontSizes = {
    'd1': 'text-[14px] font-bold',
    'd2': 'text-[14px]',
    'd3': 'text-[12px] font-bold',
    'd4': 'text-[12px]'
  };

  // 뒤로가기 버튼 클릭 핸들러
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* <Header /> */}
      <main className="flex-grow flex flex-col items-center pt-[12px] relative">
        <div className="w-[384px] max-w-[100vw] flex items-center mb-4">
          <button 
            onClick={handleBack} 
            className="flex items-center text-gray-600 hover:text-gray-800"
          >
            <ChevronLeft size={24} />
            <span className="text-sm font-medium"></span>
          </button>
        </div>
        <div className="text-xl font-bold mb-10">나의 폼</div>
        {data && examInfo && (
          <div ref={cardRef} className="w-[384px] max-w-[100vw] rounded-2xl p-6 shadow-[0_0_6px_rgba(0,0,0,0.1)] mb-[20px]">
            <div className="flex flex-col">
              <div className="grid grid-cols-9 items-center text-[14px] text-center">
                <span className="col-span-3 font-bold text-[#231F20]">{data.tree_name}</span>
                <span className="text-[#D9D9D9]">|</span>
                <span className="col-span-2 font-bold text-[#231F20]">
                  {examInfo.exam_type?.charAt(0).toUpperCase() + 
                   examInfo.exam_type?.slice(1).toLowerCase() || '유형'}
                </span>
                <span className="text-[#D9D9D9]">|</span>
                <span className="col-span-2 text-[#231F20]">{formatExamDate(examInfo.updated_at)}</span>
              </div>
              <div className="w-full h-[1px] bg-[#E5E5E5] my-[10px]"></div>
              <div className="grid grid-cols-9 items-center text-[14px] text-center">
                <span className="col-span-3 text-[#7B7979]">회차   <span className="text-[14px] text-[#231F20]">{examInfo.exam_count} </span><span className="text-[12px] text-[#231F20]">(TID · {examInfo.exam_id})</span></span>
                <span className="text-[#D9D9D9]">|</span>
                <span className="col-span-2 text-[#7B7979]">난이도   <span className="text-[14px] text-[#231F20]">{(examInfo.difficulty / 100).toFixed(2)}</span></span>
                <span className="text-[#D9D9D9]">|</span>
                <span className="col-span-2 text-[#7B7979]">문제수   <span className="text-[14px] text-[#231F20]">{examInfo.length}</span></span>
              </div>
            </div>
          </div>
        )}

        {showFloatingCard && data && examInfo && (
          <div className="fixed top-0 left-1/2 transform -translate-x-1/2 w-[384px] text-sm font-medium text-center z-50 pt-4 pb-2">
            <div className="w-full rounded-2xl p-6 bg-white shadow-[0_0_6px_rgba(0,0,0,0.1)]">
              <div className="flex flex-col">
                <div className="grid grid-cols-9 items-center text-[14px] text-center">
                  <span className="col-span-3 font-bold text-[#231F20]">{data.tree_name}</span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 font-bold text-[#231F20]">
                    {examInfo.exam_type?.charAt(0).toUpperCase() + 
                     examInfo.exam_type?.slice(1).toLowerCase() || '유형'}
                  </span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#231F20]">{formatExamDate(examInfo.updated_at)}</span>
                </div>
                <div className="w-full h-[1px] bg-[#E5E5E5] my-[10px]"></div>
                <div className="grid grid-cols-9 items-center text-[14px] text-center">
                  <span className="col-span-3 text-[#7B7979]">회차   <span className="text-[14px] text-[#231F20]">{examInfo.exam_count} </span><span className="text-[12px] text-[#231F20]">(TID · {examInfo.exam_id})</span></span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#7B7979]">난이도   <span className="text-[14px] text-[#231F20]">{(examInfo.difficulty / 100).toFixed(2)}</span></span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#7B7979]">문제수   <span className="text-[14px] text-[#231F20]">{examInfo.length}</span></span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="w-[384px] rounded-2xl p-6 shadow-[0_0_6px_rgba(0,0,0,0.1)] mb-[20px]">
          <div className="flex gap-4 text-[10px] justify-center">
            <div className="flex items-center gap-1">
              <div className="w-3 h-3 rounded bg-[#B7E4CE] opacity-70"></div>
              <span>Likely Correct</span>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-3 h-3 rounded bg-[#4ABC85]"></div>
              <span>Correct</span>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-3 h-3 rounded bg-[#FDCECE] opacity-70"></div>
              <span>Likely Incorrect</span>
            </div>
            <div className="flex items-center gap-1">
              <div className="w-3 h-3 rounded bg-[#F9344B]"></div>
              <span>Incorrect</span>
            </div>
          </div>
        </div>

        <div 
          className="w-[384px] overflow-x-auto mb-[40px]" 
          style={{ 
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            WebkitOverflowScrolling: 'touch'
          }}
        >
          <style jsx>{`
            div::-webkit-scrollbar {
              display: none;
            }
          `}</style>
          <table className="w-full text-xs">
            <thead>
              <tr>
                <th className="px-2 py-1 text-center">
                  <div className="flex items-center justify-center gap-3">
                    <button 
                      onClick={() => setSelectedLevel(getPrevLevel(selectedLevel))}
                      className="w-5 h-5 flex items-center justify-center bg-[#4ABC85] rounded-full text-white hover:bg-[#3da575] disabled:opacity-30"
                      disabled={selectedLevel === 'd1'}
                    >
                      <ChevronLeft size={14} />
                    </button>
                    <span className="text-[10px] text-gray-900">
                      {levelTitles[selectedLevel]}
                    </span>
                    <button 
                      onClick={() => setSelectedLevel(getNextLevel(selectedLevel))}
                      className="w-5 h-5 flex items-center justify-center bg-[#4ABC85] rounded-full text-white hover:bg-[#3da575] disabled:opacity-30"
                      disabled={selectedLevel === 'd4'}
                    >
                      <ChevronRight size={14} />
                    </button>
                  </div>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 1</span>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 2</span>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 3</span>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 4</span>
                </th>
                <th className="px-1 py-1 text-center w-8">
                  <span className="text-[10px] text-gray-600">Lv 5</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {getLevelData().map((item, index) => (
                <tr key={index}>
                  <td className={`px-2 py-1 text-left ${levelFontSizes[selectedLevel]}`}>
                    {item.name}
                  </td>
                  {item.status.map((status, statusIndex) => (
                    <td key={statusIndex} className="px-1 py-1 text-center">
                      <div className="flex justify-center">
                        {renderStatusBox(status)}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
}

export default ResultMapPage;
