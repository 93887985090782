import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Share, CreditCard, X, Settings, TabletSmartphone, ChevronLeft, Clock } from 'lucide-react';
import Header from '../components/Header';
import logoAnalysis from '../assets/logo_analysis.png';
import Percentile from '../components/result/percentile';
import BarChart from '../components/result/barchart2';
import ProgressBar from '../components/result/progressbar';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import axios from 'axios';
import FloatingNavButtons from '../components/FloatingNavButtons';


function MyResultPage() {
  const { code } = useParams();
  const navigate = useNavigate();
  const [analysis, setAnalysis] = useState(null);
  const [examInfo, setExamInfo] = useState(null);
  const [fieldInfo, setFieldInfo] = useState(null);
  const canvasRef = useRef(null);
  const progressCanvasRef = useRef(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [examList, setExamList] = useState([]);
  const [currentExamIndex, setCurrentExamIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const name = localStorage.getItem('student_name');
    const email = localStorage.getItem('student_email');

    if (!token || !name || !email) {
      navigate('/', { replace: true });
      return;
    }

    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/student/resultmap`,
          {
            params: {
              exam_id: code
            },
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        
        if (response.data.status === 'success' && isMounted) {
          const responseData = response.data.data;
          setAnalysis(responseData.analysis);
          setExamInfo({
            exam_type: responseData.exam_type,
            updated_at: responseData.updated_at,
            exam_count: responseData.exam_count,
            difficulty: responseData.difficulty,
            length: responseData.length,
            exam_id: responseData.exam_id
          });
          setFieldInfo({
            tree_name: responseData.tree_name
          });

          // 이전/다음 시험 ID가 있는 경우 examList 업데이트
          if (responseData.prev_exam !== null || responseData.next_exam !== null) {
            const exams = [];
            if (responseData.prev_exam) exams.push({ exam_id: responseData.prev_exam });
            exams.push({ exam_id: parseInt(code) });
            if (responseData.next_exam) exams.push({ exam_id: responseData.next_exam });
            setExamList(exams);
            setCurrentExamIndex(responseData.prev_exam ? 1 : 0);
          }
          setIsLoading(false);
        }
      } catch (error) {
        console.error('데이터를 불러오는데 실패했습니다:', error);
        setIsLoading(false);
        if (error.response?.status === 401) {
          localStorage.clear();
          navigate('/', { replace: true });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [code, navigate]);

  const graphData = useMemo(() => {
    if (!analysis) return null;

    const timeData = analysis.time_remain_reference.map(time => Math.floor(time / 60));

    const getGradeText = (grade) => {
      if (grade === 0) return "만점";
      if (grade > 5) return "6등급";  // 6등급 이상은 그냥 6등급으로 표시
      return `${grade}등급`;
    };

    const getTimeDataIndex = (targetGrade, offset = 0) => {
        const index = timeData.length - targetGrade - 1 + offset;
        return Math.min(Math.max(index, 0), timeData.length - 1);
    };

    return {
      position: analysis.target_grade,
      data: [
        { grade: analysis.target_grade + 2, time: timeData[getTimeDataIndex(analysis.target_grade, 1)], label: getGradeText(analysis.target_grade + 2) },
        { grade: analysis.target_grade + 1, time: timeData[getTimeDataIndex(analysis.target_grade)], label: getGradeText(analysis.target_grade + 1) },
        { grade: analysis.target_grade, time: Math.floor((analysis.remain_time) / 60), label: "현재 위치" },
        { grade: analysis.target_grade, time: timeData[getTimeDataIndex(analysis.target_grade)], label: getGradeText(analysis.target_grade) }
      ]
    };
  }, [analysis]);

  const getGoalTime = () => {
    if (!analysis) return 0;
    return Math.floor(analysis.remain_time / 60);
  };

  const handleShare = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/resultmap/issue`,
        { exam_id: parseInt(code) },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.data) {
        const url = `${window.location.origin}/result/${response.data.data}`;
        setShareUrl(url);
        setIsShareModalOpen(true);
      }
    } catch (error) {
      console.error('결과 공유 링크 생성 실패:', error);
      setToastMessage('결과 공유 링크 생성에 실패했습니다.');
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    }
  };

  const copyToClipboard = async (text) => {
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      // Safari 전용 복사 로직
      const tempInput = document.createElement('input');
      tempInput.value = text;
      tempInput.style.cssText = 'position: absolute; left: -9999px; top: -9999px';
      document.body.appendChild(tempInput);
      tempInput.focus();
      tempInput.setSelectionRange(0, 99999);
      
      try {
        // iOS Safari에서는 사용자 제스처(클릭) 내에서 실행되어야 함
        const successful = document.execCommand('copy');
        if (successful) {
          setToastMessage('링크가 복사되었습니다.');
        } else {
          throw new Error('복사 실패');
        }
      } catch (err) {
        setToastMessage('복사에 실패했습니다. 링크를 직접 선택해주세요.');
      } finally {
        document.body.removeChild(tempInput);
      }
    } else {
      // 다른 브라우저용 복사 로직
      try {
        await navigator.clipboard.writeText(text);
        setToastMessage('링크가 복사되었습니다.');
      } catch (err) {
        setToastMessage('복사에 실패했습니다. 링크를 직접 선택해주세요.');
      }
    }
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const handleBack = () => {
    navigate('/home');
  };

  const handlePrevExam = () => {
    if (examList.length > 0 && currentExamIndex > 0) {
      const prevExamId = examList[currentExamIndex - 1].exam_id;
      navigate(`/myresult/${prevExamId}`);
    }
  };

  const handleNextExam = () => {
    if (examList.length > 0 && currentExamIndex < examList.length - 1) {
      const nextExamId = examList[currentExamIndex + 1].exam_id;
      navigate(`/myresult/${nextExamId}`);
    }
  };

  if (isLoading || !analysis) return (
    <div className="flex flex-col min-h-screen items-center justify-center">
      <div className="w-12 h-12 border-4 border-[#4ABC85] border-t-transparent rounded-full animate-spin"></div>
      <p className="mt-4 text-gray-600 font-medium animate-pulse">분석 중입니다...</p>
    </div>
  );

  const formatExamDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(2);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  };
  
  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <main className="flex-grow flex flex-col items-center pt-[12px] relative">
        <div className="w-[384px] max-w-[100vw] flex items-center mb-4">
          <button 
            onClick={handleBack}
            className="flex items-center text-gray-600 hover:text-gray-800"
          >
            <ChevronLeft size={24} />
            <span className="text-sm font-medium">홈으로</span>
          </button>
        </div>
        <img
          src={logoAnalysis}
          alt="Analysis Logo"
          className="w-[121px] h-[26px] mb-[40px]"
        />
        <div className="w-[384px] space-y-[20px]">
          <div className="w-full rounded-2xl p-6 shadow-[0_0_6px_rgba(0,0,0,0.1)]">
            {examInfo ? (
              <div className="flex flex-col ">
                <div className="grid grid-cols-9 items-center text-[14px] text-center">
                  <span className="col-span-3 font-bold text-[#231F20]">
                    {fieldInfo.tree_name || '과목명'}
                  </span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 font-bold text-[#231F20]">
                    {examInfo.exam_type?.charAt(0).toUpperCase() + 
                     examInfo.exam_type?.slice(1).toLowerCase() || '유형'}
                  </span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#231F20]">
                    {formatExamDate(examInfo.updated_at)}
                  </span>
                </div>
                <div className="w-full h-[1px] bg-[#E5E5E5] my-[10px]"></div>
                <div className="grid grid-cols-9 items-center text-[14px] text-center">
                  <span className="col-span-3 text-[#7B7979]">회차   <span className="text-[14px] text-[#231F20]">{examInfo.exam_count} </span><span className="text-[12px] text-[#231F20]">(TID · {examInfo.exam_id})</span></span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#7B7979]">난이도   <span className="text-[14px] text-[#231F20]">{(examInfo.difficulty / 100).toFixed(2)}</span></span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#7B7979]">문제수   <span className="text-[14px] text-[#231F20]">{examInfo.length}</span></span>
                </div>
              </div>
            ) : (
              <div className="h-[72px] flex items-center justify-center">
                <p className="text-gray-500">시험 정보가 없습니다</p>
              </div>
            )}
          </div>

          <div className="w-full rounded-2xl p-4 shadow-[0_0_6px_rgba(0,0,0,0.1)]">
            <p className="text-center text-[12px] font-semibold mb-[14px]">
              {analysis.target_grade === 0 
                ? "만점까지 이만큼 남았어요"
                : `${analysis.target_grade}등급까지 이만큼 남았어요`
              }
            </p>
            <div className="flex items-baseline justify-center gap-[40px] -mb-4">
                  <Clock className="w-[20px] h-[20px] text-[#D9D9D9]" />
                  <span className="text-[48px] font-bold">{getGoalTime()}</span>
              <span className="text-[12px] font-semibold text-[#A3A1A2]">시간</span>
            </div>

            <div className="mt-[30px]">
              {graphData && <BarChart graphData={graphData} 
                                    targetGrade={analysis.target_grade}
                                    remainTime={analysis.remain_time}
                                    timeReference={analysis.time_remain_reference}
                                    progress={analysis.progress_time}/>}
            </div>

            <ProgressBar progress={analysis.progress} />
          </div>

          <Percentile percentage={analysis.percentage} />

          <div
            className="w-full rounded-2xl p-4 shadow-[0_0_6px_rgba(0,0,0,0.1)]"
          >
            <p className="text-[12px] font-semibold text-[#231F20] mb-[10px]">
              이 부분은 보완이 필요해요
            </p>

            {analysis?.priority_sector.map((item, index) => (
              <div key={index} className="h-auto bg-[#F8F8F8] rounded-2xl p-4 mb-[10px] last:mb-0">
                <div className="flex items-center gap-2 mb-1">
                  <div className="h-[20px] px-2 flex items-center bg-[#4ABC85] rounded-[10px]">
                    <span className="text-[8px] font-semibold text-white">
                      {item.tree_name.replace(/\([^)]*\)/g, '').trim()}
                    </span>
                  </div>
                  <span className="text-[12px] font-medium text-[#7B7979]">
                    {item.parent_name}
                  </span>
                </div>
                <p className="text-[14px] font-semibold text-[#231F20]">
                  {item.comp_name.replace(/\([^)]*\)/g, '').trim()}
                </p>
              </div>
            ))}
            
            <button
              onClick={() => {
                  navigate(`/myresult/${code}/map`);
              }}
              className="w-full h-[40px] bg-[#4ABC85] text-white rounded-2xl text-sm font-bold hover:bg-[#3da575] mt-4"
            >
              나의 폼 확인하기
            </button>
          </div>
        </div>
        {/* 버튼 그룹 */}
        <div className="w-[384px] flex gap-[20px] mb-[40px] mt-[20px]">
          <button 
            onClick={handleShare}
            className="w-full h-[60px] flex items-center justify-center space-x-2 bg-[#EEEEEE] text-[#7B7979] rounded-2xl hover:bg-[#E5E5E5]"
          >
            <Share size={20} />
            <span className="font-bold text-sm">공유하기</span>
          </button>
        </div>
      </main>
      
      {showToast && (
        <div className="fixed bottom-[20px] left-1/2 transform -translate-x-1/2 bg-[#231F20] text-white px-4 py-3 rounded-lg shadow-lg">
          <div className="flex items-center space-x-2">
            <span className="text-sm">{toastMessage}</span>
            <button 
              onClick={() => setShowToast(false)}
              className="ml-2"
            >
              <X size={16} />
            </button>
          </div>
        </div>
      )}
      {isShareModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-2xl p-6 w-[90%] max-w-[384px]">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">공유하기</h3>
              <button onClick={() => setIsShareModalOpen(false)}>
                <X size={24} />
              </button>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg mb-4">
              <p className="text-sm break-all select-all">{shareUrl}</p>
            </div>
            <div className="flex flex-col gap-2">
              <button
                onClick={() => copyToClipboard(shareUrl)}
                className="w-full bg-[#4ABC85] text-white py-3 rounded-xl font-bold"
              >
                링크 복사하기
              </button>
              <button
                onClick={() => setIsShareModalOpen(false)}
                className="w-full bg-gray-200 text-gray-700 py-3 rounded-xl font-bold"
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      )}
      
      {examList.length > 0 && (
        <FloatingNavButtons
          onPrev={handlePrevExam}
          onNext={handleNextExam}
          disablePrev={currentExamIndex === 0}
          disableNext={currentExamIndex === examList.length - 1}
        />
      )}
    </div>
  );
}

export default MyResultPage;