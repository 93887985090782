import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import axios from 'axios';
import HomeProgressBar from '../components/homeprogressbar';
import logoStudent from '../assets/logo_student.png';
import { ChevronRight, Clock } from 'lucide-react';

function HomePage() {
  const navigate = useNavigate();
  const [studentInfo, setStudentInfo] = useState(null);
  const [examInfo, setExamInfo] = useState(null);
  const [stamps, setStamps] = useState([]);
  const [selectedStamp, setSelectedStamp] = useState(null);
  const [examList, setExamList] = useState([]);
  const stampScrollRef = useRef(null);
  const [currentExamIndex, setCurrentExamIndex] = useState(0);
  const [stampExams, setStampExams] = useState({});

  // API 호출 상태를 추적하기 위한 ref 추가
  const isInitialMount = useRef(true);
  const fetchedStamps = useRef(new Set());

  // 로딩 상태 추가
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setStudentInfo(null);
      setExamInfo(null);
      setStamps([]);
      
      try {
        // 홈 페이지 데이터 조회 (학생 정보 포함)
        const homeResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/student/home`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
          }
        );

        if (homeResponse.data.status === 'success') {
          // 학생 정보 설정
          setStudentInfo({
            student_name: homeResponse.data.data.student_name,
            school_name: homeResponse.data.data.school_name,
            school_grade: homeResponse.data.data.school_grade
          });

          // 스탬프 정보 설정
          const stampList = homeResponse.data.data.stamp_info.map(stamp => ({
            stamp_id: stamp.exam_info[0]?.exam_id,
            tree_name: stamp.tree_name,
            exams: stamp.exam_info.map(exam => ({
              ...exam,
              is_recent: exam.exam_id === homeResponse.data.data.recent_exam_info?.exam_id
            }))
          }));

          setStamps(stampList);
          setStampExams(
            stampList.reduce((acc, stamp) => ({
              ...acc,
              [stamp.stamp_id]: stamp.exams
            }), {})
          );

          // 최근 시험 정보 설정
          if (homeResponse.data.data.recent_exam_info) {
            const recentExam = homeResponse.data.data.recent_exam_info;
            setExamInfo({
              field_info: {
                tree_name: recentExam.tree_name
              },
              exam_info: {
                exam_id: recentExam.exam_id,
                exam_type: recentExam.exam_type,
                exam_count: recentExam.exam_count,
                difficulty: recentExam.difficulty,
                length: recentExam.length,
                updated_at: recentExam.updated_at
              },
              analysis: recentExam.analysis
            });
          }
        }
        
        setIsLoading(false);
      } catch (error) {
        console.error('데이터를 불러오는데 실패했습니다:', error);
        setIsLoading(false);
        if (error.response?.status === 401) {
          localStorage.clear();
          navigate('/', { replace: true });
        }
      }
    };

    fetchData();
  }, [navigate]);

  // 이전/다음 시험으로 이동
  const handlePrevExam = () => {
    setCurrentExamIndex(prev => (prev > 0 ? prev - 1 : examList.length - 1));
  };

  const handleNextExam = () => {
    setCurrentExamIndex(prev => (prev < examList.length - 1 ? prev + 1 : 0));
  };

  // 시험 결과 페이지로 이동
  const handleExamClick = (examId) => {
    navigate(`/myresult/${examId}`);
  };

  const getTimeInHours = (seconds) => {
    return Math.floor(seconds / 60);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  // formatExamDate 함수 추가 (기존 formatDate 함수와 구분하기 위해)
  const formatExamDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(2);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  };

  // 로딩 상태일 때 보여줄 컴포넌트
  if (isLoading) return (
    <div className="flex flex-col min-h-screen items-center justify-center">
      <div className="w-12 h-12 border-4 border-[#4ABC85] border-t-transparent rounded-full animate-spin"></div>
      <p className="mt-4 text-gray-600 font-medium animate-pulse">분석 중입니다...</p>
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <main className="flex-grow flex flex-col items-center pt-[20px]">
        <div className="w-[384px] max-w-[100vw] space-y-[20px]">
          {/* 첫 번째 카드: 학생 정보 */}
          <div className="w-full flex items-end space-x-3 p-4">
            {studentInfo ? (
              <>
                <img 
                  src={logoStudent} 
                  alt="학생 로고" 
                  className="w-[40px] h-[40px]"
                />
                <div className="flex items-baseline space-x-2">
                  <span className="text-[18px] font-bold text-[#231F20]">
                    {studentInfo.student_name} 학생
                  </span>
                  <span className="text-[12px] text-[#7B7979]">
                    {studentInfo.school_name} {studentInfo.school_grade}학년
                  </span>
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center">
                <p className="text-gray-500">로딩중...</p>
              </div>
            )}
          </div>

          {/* 두 번째 카드 */}
          {examInfo && (
            <div className="w-full px-2">
              <h3 className="text-[18px] font-bold text-[#231F20]">
                최근 테스트
              </h3>
              <p className="text-[14px] text-[#7B7979] mt-1">
                가장 최근에 본 테스트 결과를 확인하세요
              </p>
            </div>
          )}
          <div className="w-full rounded-2xl p-6 shadow-[0_0_6px_rgba(0,0,0,0.1)]">
            {examInfo ? (
              <div className="flex flex-col ">
                <div className="grid grid-cols-9 items-center text-[14px] text-center">
                  <span className="col-span-3 font-bold text-[#231F20]">{examInfo.field_info.tree_name}</span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 font-bold text-[#231F20]">
                    {examInfo.exam_info.exam_type.charAt(0).toUpperCase() + 
                     examInfo.exam_info.exam_type.slice(1).toLowerCase()}
                  </span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#231F20]">{formatExamDate(examInfo.exam_info.updated_at)}</span>
                </div>
                <div className="w-full h-[1px] bg-[#E5E5E5] my-[10px]"></div>
                <div className="grid grid-cols-9 items-center text-[14px] text-center">
                  <span className="col-span-3 text-[#7B7979]">회차   <span className="text-[14px] text-[#231F20]">{examInfo.exam_info.exam_count} </span><span className="text-[12px] text-[#231F20]">(TID · {examInfo.exam_info.exam_id})</span></span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#7B7979]">난이도   <span className="text-[14px] text-[#231F20]">{(examInfo.exam_info.difficulty / 100).toFixed(2)}</span></span>
                  <span className="text-[#D9D9D9]">|</span>
                  <span className="col-span-2 text-[#7B7979]">문제수   <span className="text-[14px] text-[#231F20]">{examInfo.exam_info.length}</span></span>
                </div>
              </div>
            ) : (
              <div className="h-[72px] flex items-center justify-center">
                <p className="text-gray-500">시험 정보가 없습니다</p>
              </div>
            )}
          </div>
          <div className="w-full rounded-2xl p-6 shadow-[0_0_6px_rgba(0,0,0,0.1)]">
            {examInfo ? (
              <>
                <p className="text-[12px] font-bold text-center mb-4">
                  {examInfo.analysis.target_grade === 0 
                    ? "만점까지 이만큼 남았어요"
                    : `${examInfo.analysis.target_grade}등급까지 이만큼 남았어요`
                  }
                </p>
                <div className="flex items-baseline justify-center gap-[40px] -mb-4">
                  <Clock className="w-[20px] h-[20px] text-[#D9D9D9]" />
                  <span className="text-[48px] font-bold">{getTimeInHours(examInfo.analysis.remain_time)}</span>
                  <span className="text-[12px] font-semibold text-[#A3A1A2]">시간</span>
                </div>

                {/* 추가된 학습 정보 */}
                <div className="flex flex-col space-y-4 px-4">
                  <div className="relative">
                    <HomeProgressBar 
                      targetGrade={examInfo.analysis.target_grade}
                      remainTime={examInfo.analysis.remain_time}
                      timeReference={examInfo.analysis.time_remain_reference}
                      progress={examInfo.analysis.progress}
                    />
                    <div className="absolute inset-0">
                      <div className="flex flex-col items-start justify-center h-full pl-[105px] pt-[10px]">
                        <div className="flex flex-col items-start mb-2">
                          <span className="text-[14px] text-[#231F20]">내 학습시간</span>
                          <div className="flex items-baseline gap-2">
                            <Clock className="w-[20px] h-[20px] text-[#D9D9D9]" />
                            {/* <span className="text-[24px] font-bold">
                              {Math.round(
                                ([...examInfo.analysis.time_remain_reference].reverse()[examInfo.analysis.target_grade] - 
                                examInfo.analysis.remain_time) / 60
                              )}
                            </span> */}
                            <span className="text-[24px] font-bold">
                              {Math.round(
                                examInfo.analysis.progress_time / 60
                              )}
                            </span>
                            <span className="text-[12px] font-semibold text-[#A3A1A2]">시간</span>
                          </div>
                        </div>
                        
                        <div className="w-[100px] h-[1px] bg-[#E5E5E5]"></div>
                        
                        <div className="flex flex-col items-start mt-3">
                          <span className="text-[14px] text-[#231F20]">내 백분율</span>
                          <div className="flex items-baseline gap-2">
                            <Clock className="w-[20px] h-[20px] text-[#D9D9D9]" />
                            <span className="text-[24px] font-bold">
                              {Math.round(examInfo.analysis.percentage * 100)}
                            </span>
                            <span className="text-[12px] font-semibold text-[#A3A1A2] pl-[7px]">%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </>
            ) : (
              <div className="h-[72px] flex items-center justify-center">
                <p className="text-gray-500">시험 결과가 없습니다</p>
              </div>
            )}
          </div>

          {/* 추가된 텍스트 섹션 */}
          <div className="w-full px-2">
            <p className="text-[18px] font-bold text-[#231F20]">카이 테스트</p>
            <p className="text-[14px] text-[#7B7979] mt-1">나의 테스트 기록을 한 눈에 볼 수 있어요</p>
          </div>

          {/* 세 번째 카드: 스탬프 리스트 */}
          <div 
            ref={stampScrollRef}
            className="flex overflow-x-auto pb-4"
            style={{ 
              scrollBehavior: 'smooth',
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              overflowY: 'hidden'
            }}
            onWheel={(e) => {
              if (stampScrollRef.current) {
                e.preventDefault();
                stampScrollRef.current.scrollLeft += e.deltaY;
              }
            }}
          >
            <style jsx>{`
              div::-webkit-scrollbar {
                display: none;
              }
            `}</style>
            
            {stamps.map((stamp) => (
              <div key={stamp.stamp_id} className="flex-shrink-0 w-[320px] pr-5 last:pr-0">
                <div className="rounded-2xl p-6 shadow-[0_0_6px_rgba(0,0,0,0.1)] mt-[2px] ml-[2px]">
                  <div className="mb-4">
                    <p className="text-[14px] font-bold">{stamp.tree_name}</p>
                  </div>
                  <div className="space-y-3">
                    {stampExams[stamp.stamp_id]?.slice(0, 3).map((exam) => (
                      <div 
                        key={exam.exam_id}
                        onClick={() => exam.exam_status === 999 
                          ? navigate(`/myresult/${exam.exam_id}?stampId=${stamp.stamp_id}`) 
                          : null}
                        className={`p-4 bg-gray-50 rounded-2xl ${
                          exam.exam_status === 999 
                            ? 'cursor-pointer hover:bg-gray-100' 
                            : 'cursor-not-allowed opacity-70'
                        } transition-colors`}
                      >
                        <div className="flex justify-between items-center">
                          <div>
                            <p className="text-[12px] font-semibold">
                              Ky TEST · {exam.exam_type?.charAt(0).toUpperCase() + exam.exam_type?.slice(1).toLowerCase()}
                              {exam.is_recent && 
                                <span className="ml-2 text-[8px] bg-[#4ABC85] text-white px-[6px] py-[2px] rounded-[4px]">New</span>
                              }
                              {exam.exam_status !== 999 && 
                                <span className="ml-2 text-[8px] text-orange-500">
                                  {exam.exam_status === 200 ? '진행중' : '진행중'}
                                </span>
                              }
                            </p>
                            <div className="flex items-center space-x-2 text-[10px] text-gray-500">
                              <span>{formatExamDate(exam.updated_at)}</span>
                              <span>|</span>
                              <span>난이도: {(exam.difficulty / 100).toFixed(2)}</span>
                              <span>|</span>
                              <span>문제수: {exam.length}</span>
                            </div>
                          </div>
                          <ChevronRight className="w-5 h-5 text-gray-400" />
                        </div>
                      </div>
                    ))}
                    {(!stampExams[stamp.stamp_id] || stampExams[stamp.stamp_id].length === 0) && (
                      <p className="text-center text-gray-500 text-sm">
                        시험 기록이 없습니다
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}

export default HomePage;
