import React, { useState, useEffect } from 'react';
import { User, Lock, Phone, GraduationCap, ChevronLeft, ChevronDown, BarChart2, BookCheck, Route, Check, ChevronRight, Mail, AlertCircle } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import PrivacyPolicyForm from './PrivacyPolicyForm';
import AuthenticationModal from './AuthenticationModal';
import axios from 'axios';

const SignUpForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const referralCode = new URLSearchParams(location.search).get('referral') || '';
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    parentPhone: '',
    school: '',
    grade: '',
    grades: '',
    maxSubject: '',
    visitPath: '',
    otherVisitPath: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [parentType, setParentType] = useState('부');
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isStepValid, setIsStepValid] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authType, setAuthType] = useState('');
  const [emailError, setEmailError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    const isAllFieldsFilled = Object.values(formData).every(field => field !== '') && agreeTerms;
    setIsFormValid(isAllFieldsFilled);
  }, [formData, agreeTerms]);

  useEffect(() => {
    const checkStepValidity = () => {
      switch (currentStep) {
        case 1:
          return formData.name && formData.email && formData.password && formData.confirmPassword && formData.phone && formData.parentPhone;
        case 2:
          return formData.school && formData.grade && formData.grades && formData.maxSubject && formData.visitPath && (formData.visitPath !== '기타' || formData.otherVisitPath);
        case 3:
          return agreeTerms;
        default:
          return false;
      }
    };

    setIsStepValid(checkStepValidity());
  }, [currentStep, formData, agreeTerms]);

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength <= 3) return phoneNumber;
    if (phoneNumberLength <= 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7, 11)}`;
  };

  const handlePhoneChange = (e) => {
    const { id, value } = e.target;
    const formattedValue = formatPhoneNumber(value);
    setFormData(prev => ({ ...prev, [id]: formattedValue }));
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prev => ({ 
      ...prev, 
      [id]: id === 'email' ? value.toLowerCase() : value 
    }));
  };

  const handleInputBlur = (e) => {
    const { id, value } = e.target;
    if (id === 'email') {
      if (!validateEmail(value)) {
        setEmailError('올바른 이메일 주소를 입력해주세요.');
      } else {
        setEmailError('');
      }
    } else if (id === 'password') {
      if (!validatePassword(value)) {
        setPasswordError('8~24자의 영문 대/소문자, 숫자 조합 (대문자 1개 이상 필수)');
      } else {
        setPasswordError('');
      }
    }
  };

  const handleAuthButtonClick = (type) => {
    if (type === 'email') {
      if (!validateEmail(formData.email)) {
        setEmailError('올바른 이메일 주소를 입력해주세요.');
        return;
      }
      setEmailError('');
    }
    setAuthType(type);
    setShowAuthModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentStep === 1) {
      if (!validateEmail(formData.email)) {
        setEmailError('올바른 이메일 주소를 입력해주세요.');
        return;
      }
      setEmailError('');
    }
    
    if (currentStep < 3 && isStepValid) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 3 && isStepValid) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/student/signup`,
          {
            student_email: formData.email,
            student_name: formData.name,
            student_pw: formData.password,
            school_name: formData.school,
            school_grade: formData.grade,
            mobile_number: formData.phone,
            parent_mobile_number: formData.parentPhone,
            acq_channel: formData.visitPath === '기타' ? formData.otherVisitPath : formData.visitPath,
            referral_ticket: referralCode
          }
        );

        if (response.status === 201) {
          navigate('/signup-complete');
        }
      } catch (error) {
        let errorMessage = '회원가입 중 오류가 발생했습니다.';
        
        if (error.response) {
          switch (error.response.status) {
            case 400:
              errorMessage = '입력하신 정보가 올바르지 않습니다.';
              break;
            case 409:
              errorMessage = '이미 사용 중인 이메일입니다.';
              break;
            case 401:
              errorMessage = '인증에 실패했습니다.';
              break;
            default:
              errorMessage = '서버 오류가 발생했습니다.';
          }
        }
        
        setSubmitError(errorMessage);
      }
    }
  };

  const renderStepper = () => (
    <div className="flex items-center mb-8 relative">
      {['개인정보', '학습정보', '동의 및 수집'].map((step, index) => (
        <React.Fragment key={index}>
          <div className="flex flex-col items-center z-10 flex-1">
            <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
              currentStep > index + 1 ? 'bg-[#4ABC85] text-white' : 
              currentStep === index + 1 ? 'border-2 border-[#4ABC85] text-[#4ABC85] bg-[#4ABC85] bg-opacity-10' : 
              'bg-gray-200 text-[#A3A1A2]'
            }`}>
              {currentStep > index + 1 ? <Check size={16} /> : index + 1}
            </div>
            <span className="mt-2 text-xs">{step}</span>
          </div>
          {index < 2 && (
            <div className={`flex-1 h-[5px] rounded-[10px] ${
              currentStep > index + 1 ? 'bg-[#4ABC85]' : 'bg-[#EEEEEE]'
            }`} style={{ transform: 'translateY(-11px)' }} />
          )}
        </React.Fragment>
      ))}
    </div>
  );

  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <InputFieldWithButton
              icon={<Mail />}
              id="email"
              label="이메일"
              placeholder="이메일을 입력해 주세요"
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              value={formData.email}
              type="text"
              buttonText="인증"
              onButtonClick={() => handleAuthButtonClick('email')}
              error={emailError}
            />
            <InputField icon={<User />} id="name" label="이름" placeholder="이름을 입력해 주세요" onChange={handleInputChange} value={formData.name} />
            <InputField 
              icon={<Lock />} 
              id="password" 
              label="비밀번호" 
              placeholder="비밀번호를 입력해 주세요" 
              type="password" 
              onChange={handleInputChange} 
              onBlur={handleInputBlur}
              value={formData.password}
              error={passwordError}
            />
            <InputField icon={<Lock />} id="confirmPassword" placeholder="비밀번호를 다시 입력해 주세요" type="password" onChange={handleInputChange} value={formData.confirmPassword} />
            <InputFieldWithButton
              icon={<Phone />}
              id="phone"
              label="연락처"
              placeholder="내 전화번호를 입력해 주세요"
              onChange={handlePhoneChange}
              value={formData.phone}
              maxLength={13}
              buttonText="인증"
              onButtonClick={() => handleAuthButtonClick('phone')}
            />
            <div className="space-y-2">
              <div className="flex space-x-4 mb-2">
                <label className="flex items-center cursor-pointer">
                  <div
                    onClick={() => setParentType('부')}
                    className={`w-5 h-5 border rounded flex items-center justify-center ${
                      parentType === '부' ? 'bg-[#4ABC85] border-[#4ABC85]' : 'border-gray-300'
                    }`}
                  >
                    {parentType === '부' && <Check size={16} color="white" />}
                  </div>
                  <span className="ml-2">부</span>
                </label>
                <label className="flex items-center cursor-pointer">
                  <div
                    onClick={() => setParentType('모')}
                    className={`w-5 h-5 border rounded flex items-center justify-center ${
                      parentType === '모' ? 'bg-[#4ABC85] border-[#4ABC85]' : 'border-gray-300'
                    }`}
                  >
                    {parentType === '모' && <Check size={16} color="white" />}
                  </div>
                  <span className="ml-2">모</span>
                </label>
              </div>
              <InputField 
                icon={<Phone />} 
                id="parentPhone" 
                placeholder={`${parentType} 전화번호를 입력해 주세요`} 
                onChange={handlePhoneChange} 
                value={formData.parentPhone} 
                maxLength={13}
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <InputField icon={<GraduationCap />} id="school" label="학교" placeholder="현재 재학 중인 학교를 입력해 주세요" onChange={handleInputChange} value={formData.school} />
            <DropdownField
              id="grade"
              options={[1, 2, 3, 4, 5, 6].map(grade => ({ value: grade, label: `${grade}학년` }))}
              placeholder="학년을 선택해 주세요"
              onChange={handleInputChange}
              value={formData.grade}
            />
            <div className="space-y-2">
              <label className="block text-sm font-normal text-[#231F20]">학습상태</label>
              <InputField 
                icon={<BarChart2 />} 
                id="grades" 
                placeholder="직전 학기 성적을 입력해세요 (등급 혹은 점수)"
                onChange={handleInputChange}
                value={formData.grades}
              />
              <DropdownField
                icon={<BookCheck />}
                id="maxSubject"
                options={[
                  { value: '없음', label: '없음' },
                  { value: '공통수학1', label: '공통수학1' },
                  { value: '공통수학2', label: '공통수학2' },
                  { value: '수학I', label: '수학 I' },
                  { value: '수학II', label: '수학 II' },
                  { value: '미적분', label: '적분' },
                  { value: '확률과통계', label: '확률과 통계' },
                ]}
                placeholder="최대 학습된 과목을 선택해주세요"
                onChange={handleInputChange}
                value={formData.maxSubject}
              />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-normal text-[#231F20]">방문 경로</label>
              <DropdownField
                icon={<Route />}
                id="visitPath"
                options={[
                  { value: '인터넷검색', label: '인터넷 검색' },
                  { value: 'SNS', label: 'SNS(블로그, 카페 등)' },
                  { value: '지인소개', label: '지인 소개' },
                  { value: '지면광고', label: '지면 광고' },
                  { value: '단순방문', label: '단순 방문' },
                  { value: '기타', label: '기타' },
                ]}
                placeholder="방문 경로를 선택해주세요"
                onChange={handleInputChange}
                value={formData.visitPath}
              />
              {formData.visitPath === '기타' && (
                <InputField id="otherVisitPath" placeholder="기타 방문 경로를 입력해주세요" onChange={handleInputChange} value={formData.otherVisitPath} />
              )}
            </div>
          </>
        );
      case 3:
        return (
          <PrivacyPolicyForm 
            onAgree={() => {
              setAgreeTerms(true);
              // 여기서 회원가입 제출 로직을 실행할 수 있습니다.
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full max-w-md p-8 mx-auto">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex items-center justify-between mb-6">
          <ChevronLeft
            className="cursor-pointer"
            size={24}
            onClick={() => currentStep > 1 ? setCurrentStep(currentStep - 1) : navigate('/')}
          />
          <h2 className="text-lg font-bold flex-grow text-center mr-6">KY HUB 가입하기</h2>
        </div>

        {renderStepper()}
        {renderFormStep()}

        {currentStep < 3 && (
          <div className="pt-6">
            <button 
              type="submit" 
              className={`w-full h-[60px] bg-[#4ABC85] text-white rounded-2xl font-bold text-lg ${!isStepValid ? 'opacity-40 cursor-not-allowed' : ''}`}
              disabled={!isStepValid}
            >
              다음
            </button>
          </div>
        )}

        {submitError && (
          <div className="text-[#F9344B] text-sm flex items-center">
            <AlertCircle size={16} className="mr-1" />
            {submitError}
          </div>
        )}
      </form>
      <AuthenticationModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        type={authType}
      />
    </div>
  );
};

const InputField = ({ icon, id, label, placeholder, type = "text", onChange, onBlur, value, maxLength, error }) => (
  <div className="space-y-2">
    <div className="flex justify-between items-center">
      {label && <label htmlFor={id} className="block text-sm font-normal text-[#231F20]">{label}</label>}
      {error && (
        <span className="text-xs text-[#F9344B] flex items-center">
          <AlertCircle size={12} className="mr-1" />
          {error}
        </span>
      )}
    </div>
    <div className="relative">
      {icon && React.cloneElement(icon, { 
        className: `absolute left-3 top-1/2 transform -translate-y-1/2 ${value ? 'text-gray-900' : 'text-gray-400'}`,
        size: 20 
      })}
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        maxLength={maxLength}
        className={`w-full h-[60px] ${icon ? 'pl-10' : 'pl-3'} pr-3 bg-[#EEEEEE] border rounded-2xl text-sm focus:outline-none focus:ring-1 placeholder-gray-400 caret-[#4ABC85] ${
          error ? 'border-[#F9344B] bg-[#F9344B] bg-opacity-10 focus:border-[#F9344B] focus:ring-[#F9344B]' : 'border-[#EEEEEE] focus:border-[#4ABC85] focus:bg-opacity-10 focus:bg-[#4ABC85] focus:ring-[#4ABC85]'
        }`}
      />
    </div>
  </div>
);

const InputFieldWithButton = ({ icon, id, label, placeholder, type = "text", onChange, onBlur, value, maxLength, buttonText, onButtonClick, error }) => (
  <div className="space-y-2">
    <div className="flex justify-between items-center">
      {label && <label htmlFor={id} className="block text-sm font-normal text-[#231F20]">{label}</label>}
      {error && (
        <span className="text-xs text-[#F9344B] flex items-center">
          <AlertCircle size={12} className="mr-1" />
          {error}
        </span>
      )}
    </div>
    <div className="relative flex items-center">
      {icon && React.cloneElement(icon, { 
        className: `absolute left-3 top-1/2 transform -translate-y-1/2 ${value ? 'text-gray-900' : 'text-gray-400'}`,
        size: 20 
      })}
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        maxLength={maxLength}
        className={`w-full h-[60px] ${icon ? 'pl-10' : 'pl-3'} pr-3 bg-[#EEEEEE] border rounded-2xl text-sm focus:outline-none focus:ring-1 placeholder-gray-400 caret-[#4ABC85] ${
          error ? 'border-[#F9344B] bg-[#F9344B] bg-opacity-10 focus:border-[#F9344B] focus:ring-[#F9344B]' : 'border-[#EEEEEE] focus:border-[#4ABC85] focus:bg-opacity-10 focus:bg-[#4ABC85] focus:ring-[#4ABC85]'
        }`}
      />
    </div>
  </div>
);

const DropdownField = ({ icon, id, options, placeholder, onChange, value }) => {
  const handleChange = (e) => {
    onChange({ target: { id, value: e.target.value } });
  };

  return (
    <div className="space-y-2">
      <div className="relative">
        {icon && React.cloneElement(icon, { 
          className: `absolute left-3 top-1/2 transform -translate-y-1/2 ${value ? 'text-gray-900' : 'text-gray-400'}`, 
          size: 20 
        })}
        <select
          id={id}
          value={value}
          onChange={handleChange}
          className={`w-full h-[60px] ${icon ? 'pl-10' : 'pl-3'} pr-10 bg-[#EEEEEE] border border-[#EEEEEE] rounded-2xl text-sm focus:outline-none focus:border-[#4ABC85] focus:bg-opacity-10 focus:bg-[#4ABC85] appearance-none ${value ? 'text-gray-900' : 'text-gray-400'}`}
        >
          <option value="" disabled hidden>{placeholder}</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
        <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
      </div>
    </div>
  );
};

const validatePassword = (password) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d\S]{8,24}$/;
  return passwordRegex.test(password);
};

export default SignUpForm;