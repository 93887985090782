import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Share, CreditCard, X, Settings, TabletSmartphone, Clock } from 'lucide-react';
import Header from '../components/Header';
import logoAnalysis from '../assets/logo_analysis.png';
import Percentile from '../components/result/percentile';
import BarChart from '../components/result/barchart2';
import ProgressBar from '../components/result/progressbar';
import { useNavigate, useParams, Navigate } from 'react-router-dom';


function ResultPage() {
  const { code } = useParams();
  const navigate = useNavigate();
  const [analysis, setAnalysis] = useState(null);
  const [examInfo, setExamInfo] = useState(null);
  const canvasRef = useRef(null);
  const progressCanvasRef = useRef(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/result/${code}`;

        const response = await fetch(endpoint);
        const json = await response.json();

        if (json.status === 'success' && isMounted) {
          setAnalysis(json.data.analysis);
          setExamInfo(json.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('데이터를 불러오는데 실패했습니다:', error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [code]);

  const graphData = useMemo(() => {
    if (!analysis) return null;

    const timeData = analysis.time_remain_reference.map(time => Math.floor(time / 60));

    const getGradeText = (grade) => {
      if (grade === 0) return "만점";
      if (grade > 5) return "6등급";  // 6등급 이상은 그냥 6등급으로 표시
      return `${grade}등급`;
    };

    const getTimeDataIndex = (targetGrade, offset = 0) => {
      const index = timeData.length - targetGrade - 1 + offset;
      return Math.min(Math.max(index, 0), timeData.length - 1); // 배열 범위를 벗어나지 않도록
    };

    return {
      position: analysis.target_grade,
      data: [
        { grade: analysis.target_grade + 2, time: timeData[getTimeDataIndex(analysis.target_grade, 1)], label: getGradeText(analysis.target_grade + 2) },
        { grade: analysis.target_grade + 1, time: timeData[getTimeDataIndex(analysis.target_grade)], label: getGradeText(analysis.target_grade + 1) },
        { grade: analysis.target_grade, time: Math.floor((analysis.remain_time) / 60), label: "현재 위치" },
        { grade: analysis.target_grade, time: timeData[getTimeDataIndex(analysis.target_grade)], label: getGradeText(analysis.target_grade) }
      ]
    };
  }, [analysis]);

  const getGoalTime = () => {
    if (!analysis) return 0;
    return Math.floor(analysis.remain_time / 60);
  };


  const handleShare = () => {
    const currentUrl = window.location.href;

    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      // Safari 전용 복사 로직
      const tempInput = document.createElement('input');
      tempInput.value = currentUrl;
      tempInput.style.cssText = 'position: absolute; left: -9999px; top: -9999px';
      document.body.appendChild(tempInput);
      tempInput.focus();
      tempInput.setSelectionRange(0, 99999);

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          setToastMessage('URL이 클립보드에 복사되었습니다.');
        } else {
          throw new Error('복사 실패');
        }
      } catch (err) {
        setToastMessage('URL 복사에 실패했습니다. 직접 선택해주세요.');
      } finally {
        document.body.removeChild(tempInput);
      }
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    } else {
      // 다른 브라우저용 복사 로직
      navigator.clipboard.writeText(currentUrl)
        .then(() => {
          setToastMessage('URL이 클립보드에 복사되었습니다.');
          setShowToast(true);
          setTimeout(() => setShowToast(false), 3000);
        })
        .catch((err) => {
          console.error('클립보드 복사 실패:', err);
          setToastMessage('URL 복사에 실패했습니다.');
          setShowToast(true);
          setTimeout(() => setShowToast(false), 3000);
        });
    }
  };


  if (isLoading || !analysis) return (
    <div className="flex flex-col min-h-screen items-center justify-center">
      <div className="w-12 h-12 border-4 border-[#4ABC85] border-t-transparent rounded-full animate-spin"></div>
      <p className="mt-4 text-gray-600 font-medium animate-pulse">분석 중입니다...</p>
    </div>
  );

  const formatExamDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(2);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* <Header /> */}
      <main className="flex-grow flex flex-col items-center pt-[12px]">
        <img
          src={logoAnalysis}
          alt="Analysis Logo"
          className="w-[121px] h-[26px] mb-[40px]"
        />

        <div className="w-[384px] max-w-[100vw] rounded-2xl p-6 shadow-[0_0_6px_rgba(0,0,0,0.1)] mb-[20px]">
          {examInfo ? (
            <div className="flex flex-col">
              <div className="grid grid-cols-9 items-center text-[14px] text-center">
                <span className="col-span-3 font-bold text-[#231F20]">{examInfo.field_info.tree_name}</span>
                <span className="text-[#D9D9D9]">|</span>
                <span className="col-span-2 font-bold text-[#231F20]">
                  {examInfo.exam_info.exam_type.charAt(0).toUpperCase() +
                    examInfo.exam_info.exam_type.slice(1).toLowerCase()}
                </span>
                <span className="text-[#D9D9D9]">|</span>
                <span className="col-span-2 text-[#231F20]">{formatExamDate(examInfo.exam_info.updated_at)}</span>
              </div>
              <div className="w-full h-[1px] bg-[#E5E5E5] my-[10px]"></div>
              <div className="grid grid-cols-9 items-center text-[14px] text-center">
                <span className="col-span-3 text-[#7B7979]">회차   <span className="text-[14px] text-[#231F20]">{examInfo.exam_info.exam_count} </span><span className="text-[12px] text-[#231F20]">(TID · {examInfo.exam_info.exam_id})</span></span>
                <span className="text-[#D9D9D9]">|</span>
                <span className="col-span-2 text-[#7B7979]">난이도   <span className="text-[14px] text-[#231F20]">{(examInfo.exam_info.difficulty / 100).toFixed(2)}</span></span>
                <span className="text-[#D9D9D9]">|</span>
                <span className="col-span-2 text-[#7B7979]">문제수   <span className="text-[14px] text-[#231F20]">{examInfo.exam_info.length}</span></span>
              </div>
            </div>
          ) : (
            <div className="h-[72px] flex items-center justify-center">
              <p className="text-gray-500">시험 정보가 없습니다</p>
            </div>
          )}
        </div>

        <div className="w-[384px] max-w-[100vw] space-y-[20px] mb-[40px]">
          <div className="w-full rounded-2xl p-4 shadow-[0_0_6px_rgba(0,0,0,0.1)]">
            <p className="text-center text-[12px] font-semibold mb-[14px]">
              {analysis.target_grade === 0
                ? "만점까지 이만큼 남았어요"
                : `${analysis.target_grade}등급까지 이만큼 남았어요`
              }
            </p>
            <div className="flex items-baseline justify-center gap-[40px] -mb-4">
              <Clock className="w-[20px] h-[20px] text-[#D9D9D9]" />
              <span className="text-[48px] font-bold">{getGoalTime()}</span>
              <span className="text-[12px] font-semibold text-[#A3A1A2]">시간</span>
            </div>

            <div className="mt-[30px]">
              {graphData && <BarChart graphData={graphData}
                targetGrade={analysis.target_grade}
                remainTime={analysis.remain_time}
                timeReference={analysis.time_remain_reference}
                progress={analysis.progress_time}
              />}
            </div>

            <ProgressBar progress={analysis.progress} />
          </div>

          <Percentile percentage={analysis.percentage} />

          <div
            className="w-full rounded-2xl p-4 shadow-[0_0_6px_rgba(0,0,0,0.1)]"
          >
            <p className="text-[12px] font-semibold text-[#231F20] mb-[10px]">
              이 부분은 보완이 필요해요
            </p>

            {analysis?.priority_sector.map((item, index) => (
              <div key={index} className="h-auto bg-[#F8F8F8] rounded-2xl p-4 mb-[10px] last:mb-0">
                <div className="flex items-center gap-2 mb-1">
                  <div className="h-[20px] px-2 flex items-center bg-[#4ABC85] rounded-[10px]">
                    <span className="text-[8px] font-semibold text-white">
                      {item.tree_name.replace(/\([^)]*\)/g, '').trim()}
                    </span>
                  </div>
                  <span className="text-[12px] font-medium text-[#7B7979]">
                    {item.parent_name}
                  </span>
                </div>
                <p className="text-[14px] font-semibold text-[#231F20]">
                  {item.comp_name.replace(/\([^)]*\)/g, '').trim()}
                </p>
              </div>
            ))}

            <button
              onClick={() => {
                navigate(`/result/${code}/map`);
              }}
              className="w-full h-[40px] bg-[#4ABC85] text-white rounded-2xl text-sm font-bold hover:bg-[#3da575] mt-4"
            >
              나의 폼 확인하기
            </button>
          </div>
        </div>
        {/* 버튼 그룹 */}
        <div className="w-[384px] flex gap-[20px] mb-[40px]">
          <button
            onClick={handleShare}
            className="flex-1 h-[60px] flex items-center justify-center space-x-2 bg-[#EEEEEE] text-[#7B7979] rounded-2xl hover:bg-[#E5E5E5]"
          >
            <Share size={20} />
            <span className="font-bold text-sm">공유하기</span>
          </button>
          <button
            onClick={() => navigate(`/signup?referral=${code}`)}
            className="flex-[2] h-[60px] flex items-center justify-center space-x-2 bg-[#4ABC85] text-[#fff] rounded-2xl hover:bg-[#3da575]"
          >
            <TabletSmartphone size={20} />
            <span className="font-bold text-sm">테스트 예약하기</span>
          </button>
        </div>
      </main>

      {showToast && (
        <div className="fixed bottom-[20px] left-1/2 transform -translate-x-1/2 bg-[#231F20] text-white px-4 py-3 rounded-lg shadow-lg">
          <div className="flex items-center space-x-2">
            <span className="text-sm">{toastMessage}</span>
            <button
              onClick={() => setShowToast(false)}
              className="ml-2"
            >
              <X size={16} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResultPage;