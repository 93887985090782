import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

function FloatingNavButtons({ onPrev, onNext, disablePrev, disableNext }) {
  return (
    <div className="fixed inset-x-0 top-1/2 -translate-y-1/2 max-w-screen-lg mx-auto px-4 z-[100]">
      <div className="relative flex justify-between items-center">
        <button
          onClick={onPrev}
          disabled={disablePrev}
          className={`absolute left-0 w-12 h-12 rounded-full bg-white shadow-lg flex items-center justify-center transition-opacity
            ${disablePrev ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
        >
          <ChevronLeft size={24} className="text-gray-600" />
        </button>
        <button
          onClick={onNext}
          disabled={disableNext}
          className={`absolute right-0 w-12 h-12 rounded-full bg-white shadow-lg flex items-center justify-center transition-opacity
            ${disableNext ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
        >
          <ChevronRight size={24} className="text-gray-600" />
        </button>
      </div>
    </div>
  );
}

export default FloatingNavButtons; 