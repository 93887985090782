import React, { useEffect, useRef } from 'react';

function HomeProgressBar({ targetGrade, remainTime, timeReference, progress }) {
  const progressCanvasRef = useRef(null);
  
  useEffect(() => {
    if (targetGrade === undefined || targetGrade === null) return;
    if (!remainTime || !timeReference) return;

    const canvas = progressCanvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = 960;
    const height = 960;
    const centerX = width / 2;
    const centerY = height / 2;
    const radius = 400;
    
    // 각도 계산 (라디안)
    const startOffset = (7.5 * Math.PI) / 180; // 7.5도를 라디안으로 변환
    const startAngle = (0.5 * Math.PI) + startOffset; // 남쪽(90도)에서 시작
    const totalAngle = (1.5 * Math.PI) - (2 * startOffset); // 전체 호의 각도
    
    // Canvas 초기화
    ctx.clearRect(0, 0, width, height);
    
    // 진행률 계산
    const gradeIndex = targetGrade;
    const targetTime = timeReference[gradeIndex];
    const progressPercent = Math.round(progress * 100);
    
    // 배경 원 그리기 (회색)
    ctx.beginPath();
    ctx.lineCap = 'round';  // 선 끝을 둥글게
    ctx.arc(centerX, centerY, radius, startAngle, startAngle + totalAngle);
    ctx.lineWidth = 60;
    ctx.strokeStyle = '#EEEEEE';
    ctx.stroke();
    
    // 진행률 원 그리기 (초록색)
    ctx.beginPath();
    ctx.lineCap = 'round';  // 선 끝을 둥글게
    const progressAngle = startAngle + (totalAngle * progressPercent / 100);
    ctx.arc(centerX, centerY, radius, startAngle, progressAngle);
    ctx.lineWidth = 60;
    ctx.strokeStyle = '#4ABC85';
    ctx.stroke();

    // 범례 그리기
    const legendX = centerX + 240;
    const legendY = centerY + 180;
    const boxSize = 48;
    const textOffset = 70;
    const legendSpacing = 10;
    
    // 초록색 상자와 텍스트
    ctx.fillStyle = '#4ABC85';
    ctx.beginPath();
    ctx.roundRect(legendX, legendY - boxSize - legendSpacing, boxSize, boxSize, 16);
    ctx.fill();
    ctx.fillStyle = '#A3A1A2';
    ctx.font = '30px sans-serif';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'middle';
    ctx.fillText('학습 시간', legendX + textOffset, legendY - boxSize/2 - legendSpacing);
    
    // 회색 상자와 텍스트
    ctx.fillStyle = '#EEEEEE';
    ctx.beginPath();
    ctx.roundRect(legendX, legendY + legendSpacing, boxSize, boxSize, 16);
    ctx.fill();
    ctx.fillStyle = '#A3A1A2';
    ctx.fillText('남은 시간', legendX + textOffset, legendY + boxSize/2 + legendSpacing);

    // 진행률 텍스트 그리기
    const roundedProgress = Math.round(progress * 100); // 소수점 첫째 자리에서 반올림
    
    // 둥근 직사각형 배경 그리기
    const rectWidth = 280;
    const rectHeight = 110;
    const cornerRadius = 55;
    const rectX = centerX + 150 - rectWidth/2;
    const rectY = centerY + radius - rectHeight/2 - 20;

    ctx.beginPath();
    ctx.moveTo(rectX + cornerRadius, rectY);
    ctx.lineTo(rectX + rectWidth - cornerRadius, rectY);
    ctx.arcTo(rectX + rectWidth, rectY, rectX + rectWidth, rectY + cornerRadius, cornerRadius);
    ctx.lineTo(rectX + rectWidth, rectY + rectHeight - cornerRadius);
    ctx.arcTo(rectX + rectWidth, rectY + rectHeight, rectX + rectWidth - cornerRadius, rectY + rectHeight, cornerRadius);
    ctx.lineTo(rectX + cornerRadius, rectY + rectHeight);
    ctx.arcTo(rectX, rectY + rectHeight, rectX, rectY + rectHeight - cornerRadius, cornerRadius);
    ctx.lineTo(rectX, rectY + cornerRadius);
    ctx.arcTo(rectX, rectY, rectX + cornerRadius, rectY, cornerRadius);
    ctx.closePath();
    
    ctx.fillStyle = '#4ABC85';
    ctx.fill();
    
    // 텍스트를 정확히 가운데 정렬
    ctx.fillStyle = '#FFFFFF';
    ctx.font = 'bold 40px sans-serif';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    const textY = rectY + (rectHeight / 2) + 3;
    ctx.fillText(`${roundedProgress}% 달성됨`, centerX + 150, textY);

  }, [targetGrade, remainTime, timeReference, progress]);

  return (
    <div className="mt-4">
      <canvas 
        ref={progressCanvasRef}
        width={960}
        height={960}
        className="w-[300px] h-[300px]"
      />
    </div>
  );
}

export default HomeProgressBar; 